import React from 'react'
import Layout from "../components/App/Layout"
import AboutUsContent from '../components/AboutUs/AboutUsContent'
import OurMission from '../components/AboutUs/OurMission'
import WhyChooseUs from '../components/AboutUs/WhyChooseUs'
import Testimonials from '../components/Common/Testimonials'
import PageBannerBackground from '../components/Common/PageBannerBackground' 
 
const AboutUs = () => {
    return (
        <Layout page="About Us">
            <PageBannerBackground
                pageTitle="ABOUT US" 
                crumbs={[{page: "Home", url: "/"}]}    
                bgFileName="about/about-us-banner.jpg" 
            />
            <AboutUsContent />
            <WhyChooseUs />
            <OurMission />
            <Testimonials />
        </Layout>
    );
}

export default AboutUs;