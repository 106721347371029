import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import { StaticImage } from "gatsby-plugin-image"

const AboutUsContent = () => {
  return (
    <section className="about-area ptb-70">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <StaticImage
                src="../../assets/images/about/who-we-are.jpg"
                placeholder="blurred"
                alt="Who We Are"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={starIcon} alt="banner" />
                  About Us
                </span>
                <h2>Who We Are</h2>
                <p>
                  We are a well-being consultancy promoting a movement of world
                  connection, awareness, respect, knowledge, and love.
                  <br /><br />
                  Connection Goal is not only a place to learn and develop new
                  skills, but It is also a supportive, fun, and safe environment
                  for all, an opportunity to connect with a powerful
                  family-oriented for knowledge, action, and transformation to
                  make the world a better place. Sharing and connecting is a
                  chain of goodness diversity movement.
                  <br /><br />
                  For us, the meaning of connection is energy: the powerful energy that surrounds us everywhere and every second in all dimensions.
                  <br />
                  We genuinely believe that we already have inside us everything that we need. <br/><br/>Our mission is to guide and inspire
                  people to learn and achieve the potential source of energy
                  living inside all, bringing the community sense, happiness,
                  and fulfilment to the world and the next generation.
                  <br /><br />
                  Creating a respectful, positive, healthy, and learning
                  environment where everyone is connected and inspired by the
                  together power.
                  <br /><br />
                  Chain of goodness diversity movement. Come with us on this
                  journey and bring your energy to connect with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUsContent
