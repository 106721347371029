import React from "react"
import shape1 from "../../assets/images/shape/shape1.svg"
import shape2 from "../../assets/images/shape/shape2.png"
import shape3 from "../../assets/images/shape/shape3.png"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import Breadcrumbs from '../App/Breadcrumbs'

const PageBannerBackground = ({
  pageTitle,
  crumbs,
  bgFileName
}) => {

    const data = useStaticQuery(
        graphql`
            query {
                placeholderImage: allFile(
                    filter: { sourceInstanceName: { eq: "assets" } }
                ) {
                    edges {
                        node {
                            relativePath
                            childImageSharp {
                                fluid(quality: 95, maxWidth: 1920) {
                                  ...GatsbyImageSharpFluid_withWebp
                                }
                              }
                        }
                    }
                }
            }
        `
    );
 
    const image = data.placeholderImage.edges.find(
        ({ node }) => node.relativePath === bgFileName
    ).node;

/*
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "assets/images/kids/kids-banner.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid
  */

  return (
    <div>
      <BackgroundImage
        Tag="section"
        //fluid={imageData}
        fluid={image.childImageSharp.fluid}
        className="masthead"
        backgroundColor={`#040e18`}
      >
        <div className="black-overlay"> 
          <div className="page-title-content content-box">
            <h2>{pageTitle}</h2>
            <Breadcrumbs crumbs={crumbs} />
          </div>
        </div>
      </BackgroundImage>

      <div className="shape-img1">
        <img src={shape1} alt="banner" />
      </div>
      <div className="shape-img2">
        <img src={shape2} alt="banner" />
      </div>
      <div className="shape-img3">
        <img src={shape3} alt="banner" />
      </div>
    </div>
  )
}

export default PageBannerBackground
