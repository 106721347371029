import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import { StaticImage } from "gatsby-plugin-image"

const OurMisson = () => {
  return (
    <section className="about-area ptb-70">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <StaticImage src="../../assets/images/about/our-mission.jpg" placeholder="blurred" alt="Our Mission" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={starIcon} alt="banner" />
                  People Love Us
                </span>
                <h2>Our Misson</h2>
                <p>
                  Connection Goal Ltd is dedicated to educate, support and empower people through wellbeing. Connection Goal accomplishes this mission by teaching, providing wellbeing education tools, treatments, courses, training, workshops, and helping all diversity in the globe.
                  <br />
                  <br />
                  You matter. We matter.
                  <br />
                  <br />
                  Experience, move, belong and Inspire people around the globe through the power of learning and collaborative action, developing deep knowledge, creative minds, and innovative technologies for good that make ours the world’s a better place for all and new generations.
                  <br />
                  <br />
                  We are stronger together. We are all connected.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurMisson
