import React from "react"
import starIcon from "../../assets/images/star-icon.png"

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area ptb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src={starIcon} alt="banner" />
                About Me
              </span>
              <h2>Our Founder</h2>
              <p>
              Miriam Bueno is the founder and CEO of Connection Goal Ltd, a brilliant personal and corporate well-being consulting firm.
               Brazilian
                by birth, She is a contented resident of the United Kingdom.
                <br /><br />
                Her passion has always been to help people. She finds diversity,
                learning to share, and connecting with all to be the most
                valuable qualities for success in running her company. In the 3
                years since founding Connection Goal, Miriam has supported and
                helped people around the globe to conquer wellbeing, better
                mental health, and make wonderful friends connection as a chain
                of the good, helping each other.
                <br /><br />
                Miriam holds over 20 years leading and teaching teams to create
                solutions in professional and personal lives. Her professional
                resume includes working in Top multinational companies with
                diversity, wellbeing, and social projects.
                <br /><br />
                Her personal story has led to creating Connection Goal as a safe
                place to learn and embrace diversity to make the world a
                supportive and better place to live. Miriam hopes to continue
                using her determination, creativity, and quick-thinking skills
                to turn Connection Goal into the best 10 companies to work in the
                world, inspiring and connecting hearts!
              </p>
              {/*<p>
                            Brazilian by birth, I am a contented resident of the United Kingdom. My professional resume includes twenty years working in multinational companies with diversity, well-being and social projects. My passion has always been to help people. As a person who supported C+ levels, I had to be versatile and strategic to solve problems and to make people shine.<br/><br/>  
                            I love to inspire and guide people to find solutions, passion and well-being. I have been developing many tools, methods that make people’s lives easier and more exciting! I have over 20 years leading and teaching teams to create solutions and find purpose at the same time, in their professional and personal lives in a deep conscious connection.<br/>
                            I was 12 years old when I developed an obsession for books and a public library (the only place allowed to visit alone by my parents) as a scape and hope, looking for knowledge to understand my family behaviours and feelings. Reading connected me to life. Reading psychology and other alternative sciences helped me understand my parents, others, and myself in deeply meaningful ways.<br/><br/>
                            Since then my desire to learn and improve as a woman never has stopped. I immersed myself in a journey of learning, complementary alternative medicines courses, spiritualistic groups and social projects. The economic situation in Brazil was a challenge to survive with the learning journey but luckily my talent to solve people problems guided me to a parallel career as an Executive Assistant and I could afford my alternative studies and apply in my activities as a laboratory.<br/><br/> 
                            My professional resume includes more than twenty years working with C+ executives, diversity and wellbeing, and  social projects. I learned a lot and developed people and business skills. As an Executive Secretary It's important to use super powers such as intuition, connection, flexibility, intelligence, creativity and observation to help executives and team members to find strategic solutions for all, and that's something I love doing.<br/><br/>
                            Finding out how people tick really matters to me and Teamwork makes so much difference to how you feel as a human being.<br/> 
                            I realised that We are all intimately connected and capable of changing any reality together. I have found supporting and teaching people was my mission.<br/>
                            In 2010, as a result of a high demanding personal and professional life, my mental health was impacted by anxiety, panic attacks, and stress. They had created many fears in her mind, and while they didn’t have a basis in reality, they felt very real and terrifying to me. Combined with some other stressful life events, these panic attacks caused me to lose my social life, my confidence, and ultimately, my self.<br/><br/> 
                            I have had my career stopped for several months to recover my life balance and again, alternative resources, music, and my desire to learn more about the unknown and find a solution for my actual situation, promoted a deeply personal healing process without any pharmaceutical drugs being used.<br/> 
                            All my passion for helping people was born from my own mental health and life challenges, and the path I took to overcome them.<br/><br/>
                            Like a hurricane, 2015, a remarkable year in my life, started  meeting my future husband in January, followed by a job offer in London in June, the year just ended with a flourish, getting married in December.  For the years passed,I adapted to a different culture, worked for more executives, made new friends, studied, conducted several social and diversity projects, connected with important and respectful associations.<br/><br/>
                            I wanted an opportunity to help shape people's lives and do good in the world, "I wanted a job that allowed me to make a difference, do good things, have a big impact on the world and at the same time be able to rise my daughter with good  examples" - Míriam Bueno. I finally mustered the courage and my mission of helping people for the next level, starting my own business to share knowledge, fulfilment and transformation.<br/><br/> 
                            Today I dedicate my life to my family, patients and students, doing what I love: creating solutions and guidance for all.<br/><br/>  
                            </p>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
