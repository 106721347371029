import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = props => {
  return (
    <div>
      <ul>
        {props.crumbs.map((crumb, index) =>
           (
            <li key={index}>
               <Link to={crumb.url}>{crumb.page}</Link>
            </li>
          ) 
        )}
      </ul>
    </div>
  )
}

export default Breadcrumbs
